/* *** RSS page *** */
#posts_module .rss_link_image svg {
	width: 24px;
	height: 24px;
	vertical-align: bottom;
}

#posts_module .rss_link_image svg {
	fill: currentColor;
}

/* *** Subscription *** */
#posts_module.form .instructions {
	font-weight: bold;
	padding: 0px 0px 15px 0px;
}

#posts_module.form .form_container {
	border-spacing: 2px;
}

#posts_module.form .ui-form-fieldset {
	border: none;
}


#posts_module.form .required_message {
	text-align: right;
	margin-bottom: 10px;
}

#posts_module.form .required_message span:before {
	content: "*";
	color: #eb0000;
	padding-right: 5px;
	font-style: italic;
}


#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	display: inline-block;
	width: 35%;
	padding: 3px 5px 0px 0px;
	text-align: right;
}

#posts_module.form .form_item .required .ui-form-label label:after {
	content: "*";
	color: #eb0000;
	padding-left: 5px;
	font-style: italic;
}

#posts_module.form .form_item .ui-form-input,
#posts_module.form .form_item .ui-form-note,
#posts_module.form .form_item table {
	display: inline-block;
}

#posts_module.form .form_item .ui-form-input input {
	width: 348px;
}

#posts_module.form .form_item table {
	padding-top: 4px;
	margin-left: 3px;
	vertical-align: text-top;
	width: calc(65% - 10px);
}

#posts_module.form .form_item table td.checkbox {
	display: block;
	width: 100%;
	overflow: hidden;
}

#posts_module.form .form_item table td.checkbox label {
	font-weight: inherit;
}

#posts_module.form .form_item .checkbox input {
	float: left;
	margin-top: 2px;
	width: 10px;
}

#posts_module.form .form_item .checkbox .ui-checkbox-label {
	margin-top: -4px;
	margin-left: 4px;
	margin-right: 10px;
	float: left;
	width: calc(100% - 30px);
}

#posts_module.form .form_item .required .ui-form-label em {
	display: none;
}

#posts_module.form .ui-form-buttons {
	margin-top: 15px;
	text-align: center;
}

#posts_module.form .ui-form-buttons .ui-form-button {
	cursor: pointer;
}

#posts_module.form .form_item .ui-form-error {
	display: block;
	margin-left: 35%;
	color: #840000;
}

#posts_module .rss_link_image {
	text-decoration: none !important;
	border: 0px !important;
}

.posts-blocks-recent-post {
	margin-bottom: 10px;
}

.posts-node .post-add-comment {
	padding-left: 20px;
	background: url( "/core/modules/posts/assets/images/new_comment.gif" ) no-repeat;
}

.posts-node .post-view-comments {
	padding-left: 20px;
	background: url( "/core/modules/posts/assets/images/comments.gif" ) no-repeat;
}

.posts-node .posts-comments {
	margin: 10px 0 0 0;
}

.posts-node .post-comments-title {
	width: 100%;
	margin-top: 10px;
	background-color: #555;
	padding: 5px;
	font-weight: bold;
	color: #ffffff;
}

.posts-node .posts-comment-form-required-info {
	text-align: right;
}

.posts-node .post-comment {
	padding: 5px;
}

.posts-node .post-comment {
	border-bottom: 1px dotted #d3d3d3;
}

.posts-node .post-comment-author {
	padding-top: 5px;
	font-size: 0.625rem;
}

.posts-node .post-comment-media {
	padding: 10px 0;
}

.posts-node .post-comment-media video {
	max-width: 100%;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields {
	margin-bottom: 10px;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1.125rem;
	font-weight: bold;
	margin: 10px 0;
	padding-bottom: 5px;
	width: 100%;
	float: left;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input {
	width: 350px;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .post-comment-consent-confirmation .ui-form-input input {
	width: auto;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea {
	width: 350px;
	resize: vertical;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields:last-of-type {
	margin-bottom: 0;
}

.posts-node .posts-comments #post-add-comment .ui-form-buttons {
	padding: 12px 0 0 0;
}

.posts-node .posts-comments .no-comments-yet {
	margin: 10px 0;
}

/*right sidebar*/
.posts-blocks {
	margin-bottom: 20px;
	border-bottom: 1px solid #ccc;
	padding-bottom: 20px;
}

.posts-blocks.posts-blocks-searches {
	margin-bottom: 20px;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	display: inline;
	height: 42px;
	width: calc(100% - 42px);
	margin-right: 0;
	padding: 8px;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 12px 12px 6px !important;
	margin: 0;
	display: inline;
	vertical-align: top;
	max-height: 42px;
	border-radius: 0;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button svg {
	width: 18px;
	height: 18px;
}

.posts-blocks.posts-blocks-rss-feeds {
	margin-bottom: 20px;
}

.posts-blocks.posts-blocks-rss-feeds .posts-blocks-rss-feed .posts-blocks-rss-feed-link svg {
	width: 24px;
	height: 24px;
	margin-right: 5px;
	vertical-align: bottom;
}

.posts-blocks.posts-blocks-rss-feeds .posts-blocks-rss-feed .posts-blocks-rss-feed-link svg {
	fill: currentColor;
}

.posts-blocks.posts-blocks-subscribes {
	margin-bottom: 20px;
}

.posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe a {
	display: block;
	overflow: hidden;
}

.posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe svg {
	width: 24px;
	height: 24px;
	margin-right: 9px;
	vertical-align: bottom;
	float: left;
}

.posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe svg {
	fill: currentColor;
}

.posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe span {
	float: left;
	width: calc(100% - 33px);
}

.posts-blocks.posts-blocks-author-latest-posts .posts-blocks-author-latest-posts-abstract {
	margin-bottom: 10px;
}

.posts-blocks.posts-blocks-author-latest-posts .posts-blocks-author-latest-posts-abstract:last-of-type {
	margin-bottom: 0;
}

.posts-blocks .hidden-assistive-content-wcag {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

.field.posts-field.posts-field-image img {
	max-width: 100%;
	height: auto;
	width: auto;
	margin-bottom: 10px;
}

.field.posts-field.post-details-image img {
	width: 100%;
	height: auto;
	margin-bottom: 10px;
}

.field.posts-field.post-details-image.post-details-youtube iframe {
	aspect-ratio: 16 / 9;
	width: 100%;
}

.field.posts-field.post-details-image.post-details-video video {
	max-width: 100%;
	height: auto;
}

.required_information {
	color: #eb0000;
	padding-right: 5px;
	font-weight: 400;
	font-style: italic;
}

.ui-form-field.required .ui-form-label em {
	color: #eb0000;
	font-weight: 400;
	font-style: italic;
}

.field p {
	margin-top: inherit;
}

/*list with images content box*/
.posts-collection.homepage-contentbox.article-items {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 50px;
	text-align: center;
}

.posts-collection.homepage-contentbox.article-items .article {
	padding-left: 20px;
	padding-right: 20px;
	text-align: left;
	text-decoration: none;
}

.posts-collection.homepage-contentbox.article-items .article-image-link {
	display: block;
	max-width: 100%;
}

.posts-collection.homepage-contentbox.article-items .article-image {
	width: 100%;
	max-width: 100%;
	display: inline-block;
	vertical-align: middle;
}

.posts-collection.homepage-contentbox.article-items .article-youtube-wrapper iframe {
	aspect-ratio: 16 / 9;
	width: 100%;
}

.posts-collection.homepage-contentbox.article-items .article-video-wrapper video {
	max-width: 100%;
	height: auto;
}

.posts-collection.homepage-contentbox.article-items .article-date {
	background-image: url("/core/public/shared/assets/images/calendar.svg");
	background-position: 0 50%;
	background-repeat: no-repeat;
	background-size: 15px 15px;
	font-size: 0.85em;
	margin-top: 10px;
	padding-left: 20px;
}

.posts-blocks-practice-areas ul {
	list-style-type: none;
}

.posts-blocks-practice-areas .post-assigned-practice-areas {
	padding-left: 0px !important;
}

.posts-blocks-practice-areas ul li {
	padding-left: 0px;
}

/* grid view */
.module-posts .post-collection-grid-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.module-posts .post-collection-grid-container .post-collection-grid-block {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 250px;
	margin-right: 5px;
	margin-bottom: 20px;
	padding-top: 0px;
	padding-right: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	cursor: pointer;
	width: 32%;
	max-width: 32%;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 31%;
	flex: 1 1 31%;
	overflow: hidden;
	border-bottom: 0 !important;
}

.module-posts .post-collection-grid-container .post-collection-grid-block.size-2 {
	width: 50%;
	max-width: 50%;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 48%;
	flex: 1 1 48%;
}

.module-posts .post-collection-grid-container .post-collection-grid-block.size-3 {
	width: 32%;
	max-width: 32%;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 31%;
	flex: 1 1 31%;
}

.module-posts .post-collection-grid-container .post-collection-grid-block.size-4 {
	width: 24%;
	max-width: 24%;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 23%;
	flex: 1 1 23%;
}

.module-posts .post-collection-grid-container .post-collection-grid-block .heading {
	margin-top: 0px;
	margin-bottom: 0px;
	color: #000;
	line-height: 1.2em;
	font-weight: 700;
	font-size: 1rem;
	text-align: center;
	padding: 0 5px;
	transition: all 300ms ease;
}

.module-posts .post-collection-grid-container .post-collection-grid-block .post-collection-grid-block-date .heading {
	font-size: 0.9rem;
}

.module-posts .post-collection-grid-container .post-collection-grid-block .post-collection-grid-block-date {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.85);
	opacity: 1;
	font-size: 0.75em;
	min-height: 25px;
}

.module-posts .post-collection-grid-container .post-collection-grid-block .post-collection-grid-block-title {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 20%;
	min-height: 20%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.85);
	opacity: 1;
	line-height: 1em;
	transition: all 300ms ease;
}

.module-posts .post-collection-grid-container .post-collection-grid-block:hover .post-collection-grid-block-title {
	height: 25%;
}

@media (min-width: 992px) {
	.module-posts .post-collection-grid-container .post-collection-grid-block.size-2:nth-child(2n) {
		margin-right: 0;
	}

	.module-posts .post-collection-grid-container .post-collection-grid-block.size-3:nth-child(3n) {
		margin-right: 0;
	}

	.module-posts .post-collection-grid-container .post-collection-grid-block.size-4:nth-child(4n) {
		margin-right: 0;
	}
}

@media (max-width: 991px) {
	.module-posts .post-collection-grid-container {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.module-posts .post-collection-grid-container .post-collection-grid-block.size-2,
	.module-posts .post-collection-grid-container .post-collection-grid-block.size-3,
	.module-posts .post-collection-grid-container .post-collection-grid-block.size-4 {
		width: 50%;
		max-width: 50%;
		-ms-flex: 1 1 48%;
		flex: 1 1 48%;
		margin: 0 10px 10px 0;
	}

	.module-posts .post-collection-grid-container .post-collection-grid-block:nth-child(even) {
		margin-right: 0;
	}
}

@media (max-width: 767px) {
	.module-posts .post-collection-grid-container .post-collection-grid-block {
		width: 100%;
		height: 275px;
	}
}

@media (max-width: 479px) {
	.module-posts .post-collection-grid-container .post-collection-grid-block.size-2,
	.module-posts .post-collection-grid-container .post-collection-grid-block.size-3,
	.module-posts .post-collection-grid-container .post-collection-grid-block.size-4 {
		height: 250px;
		width: 100%;
		max-width: 100%;
		-ms-flex: 1 1 100%;
		flex: 1 1 100%;
		margin: 0 0 10px 0;
	}
}

.posts-blocks .posts-blocks-date-filter form.posts-blocks-date-filter-form select {
	width: 100%;
	margin-bottom: 10px;
}

.posts-blocks .posts-blocks-date-filter form.posts-blocks-date-filter-form select:last-of-type {
	margin-bottom: 0;
}
